import { queryOptions} from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';

export const getProviderQuery = (providerId: string) =>
  queryOptions({
    queryFn: async () =>
      (await crownApi.ProviderAdmin_getProvider({ providerId })).data,
    queryKey: ['providers', 'provider', { providerId }],
  });
