import {
  DefaultError,
  infiniteQueryOptions,
  keepPreviousData,
  UseMutationOptions,
} from '@tanstack/react-query';

import { crownApi, crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';

export const listProviderTeamUserQuery = ({
  perPage,
  providerId,
  filters,
}: Omit<Components.Schemas.GetProviderTeamUsersParamsDto, 'page' | 'limit'> & {
  perPage: number;
  providerId?: string;
}) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.ProviderTeam_getUsers({
          providerId: providerId as string,
          page: pageParam,
          limit: perPage,
          filters,
        })
      ).data;
    },
    queryKey: ['provider', 'team', 'list', { filters, perPage }],
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });

export const getInviteProviderUserMutation = (
  providerId: string,
): UseMutationOptions<
  Components.Schemas.InviteProviderUserResponseDto,
  DefaultError,
  Components.Schemas.InviteProviderUserParamsDto
> => ({
  mutationFn: async (user: Components.Schemas.InviteProviderUserParamsDto) => {
    return (await crownApi.ProviderTeam_inviteUser(providerId, user)).data;
  },
  onSuccess: () => {
    void crownQueryClient.invalidateQueries({
      queryKey: ['provider', 'team', 'list'],
    });
  },
});
