import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { z } from 'zod';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Button } from '@/components/ui/Button.tsx';
import CurrencyInput from '@/components/ui/CurrencyInput.tsx';
import { Input } from '@/components/ui/Input.tsx';
import { Select } from '@/components/ui/Select.tsx';
import { Components } from '@/openapi';
import { listTestTransactionDestinationsQuery } from '@/testing/api.ts';

const createTransactionSchema = z.object({
  description: z.string().min(1),
  amount: z.string().min(1),
  postDate: z.coerce.date().transform(d => dayjs(d).format('YYYY-MM-DD')),
  destination: z.string().min(1),
});

export const CreateTestTransaction = () => {
  const form = useForm<
    z.input<typeof createTransactionSchema>,
    any,
    z.output<typeof createTransactionSchema>
  >({
    defaultValues: {
      description: '',
      amount: '0',
      postDate: dayjs().format('YYYY-MM-DD') as unknown as Date,
      destination: '',
    },
    resolver: zodResolver(createTransactionSchema),
  });
  const { register } = form;

  const { mutate, isPending } = useMutation({
    mutationFn: async (
      params: Components.Schemas.CreateTestTransactionParamsDto,
    ) => (await crownApi.QATesting_createTestTransaction(null, params)).data,
    onSuccess: (data) => {
      toast.success(`Test transaction created with ID ${data.transactionId}`);
    },
  });

  const { data: destinationsResponse } = useQuery(
    listTestTransactionDestinationsQuery,
  );

  const onSubmit = (values: z.output<typeof createTransactionSchema>) => {
    mutate({
      amount: values.amount,
      destination: destinationsResponse!.destinations.find(
        (dest) => dest.id === values.destination,
      )!,
      description: values.description,
      postDate: values.postDate,
    });
  };

  return (
    <FormProvider {...form}>
      <form
        className={'flex items-top mb-4 gap-x-2 justify-stretch w-full'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Input
          {...register('description')}
          label={'Description'}
          className={'flex-grow'}
        />
        <CurrencyInput
          {...register('amount')}
          label={'Amount'}
          helperText={'Use a negative value for a debit transaction'}
          name={'amount'}
          className={'flex-grow'}
        />
        <Input
          {...register('postDate')}
          label={'Post Date'}
          type={'date'}
          name={'postDate'}
          className={'flex-grow'}
        />
        <Controller
          name={'destination'}
          control={form.control}
          render={({ field }) => (
            <Select
              disabled={!destinationsResponse}
              label={'Destination'}
              value={field.value}
              className={'flex-grow'}
              onChange={(newValue) => field.onChange(newValue)}
              options={
                destinationsResponse?.destinations?.map((destination) => ({
                  label: `${destination.name} (${destination.type === 'proxy' ? 'Proxy' : 'Virtual'})`,
                  value: destination.id,
                })) || []
              }
            />
          )}
        />

        <Button
          type={'submit'}
          className={'self-center'}
          size={'lg'}
          loading={isPending}
        >
          Create Transaction
        </Button>
      </form>
    </FormProvider>
  );
};
